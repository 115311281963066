<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="自动营销建议" name="1"></el-tab-pane>
        <el-tab-pane label="自动打标签建议" name="2"></el-tab-pane>
      </el-tabs>

      <div v-if="activeName==1">
        <div>
          <div class = "titfont">
            <span>新客转化</span>
            <span class = "secfont">针对从没有在商城里支付成功过的客户进行交易转化</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem v-for="(x,y) in marketlist1" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>老客促活</span>
            <span class = "secfont">针对商城里的老客户提高活跃度，提升复购等</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem v-for="(x,y) in marketlist2" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>客户忠诚与关怀</span>
            <span class = "secfont">针对商城里的会员或意向会员进行忠诚度培养、日常关怀问候等，以保持与客户间的联系</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem v-for="(x,y) in marketlist3" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>客户社交层级</span>
            <span class = "secfont">针对商城里喜欢分享的客户，进行推手转化，或者针对商城现有推手进行升级引导</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem v-for="(x,y) in marketlist4" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>商品成交与复购</span>
            <span class = "secfont">针对商品，对商品有意向或购买过商品的客户，进行成交转化，或复购提升等</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem v-for="(x,y) in marketlist5" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
          </div>
        </div>
      </div>

      <div v-else-if="activeName==2">
        <div>
          <div class = "titfont">
            <span>客户分类</span>
            <span class = "secfont">针对客户消费次数、消费金额、充值、生命周期等对客户进行分类</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem2 v-for="(x,y) in templateList1" :key="y" :itemData="x" style="margin:10px" @click="jumpautotag(x)"></labeltem2>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>商品类标签</span>
            <span class = "secfont">针对客户购买商品、浏览商品、分享商品等行为，给客户打上标签</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem2 v-for="(x,y) in templateList2" :key="y" :itemData="x" style="margin:10px" @click="jumpautotag(x)"></labeltem2>
          </div>
        </div>
        <div>
          <div class = "titfont">
            <span>营销类标签</span>
            <span class = "secfont">针对客户参与的营销活动次数，给客户打上标签</span>
          </div>
          <div class = "flexRow" style="flex-wrap:wrap">
            <labeltem2 v-for="(x,y) in templateList3" :key="y" :itemData="x" style="margin:10px" @click="jumpautotag(x)"></labeltem2>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import store from "@/store/index"
import {mapGetters} from "vuex"
import {generateTitle} from '@/utils/i18n'


import labeltem from "./components/labeltem"
import labeltem2 from "@/views/Customer/autoAddTag/components/labeltem"
import marketcompon from './marketcompon'
import {
		templateList
	} from '@/views/Customer/autoAddTag/js/normalTemplate.js'
export default {
  components: {
    labeltem,
    labeltem2
  },
  data () {
    return {
      activeName:'1',
      newmarketcompon:[],
      marketlist1:[],
      marketlist2:[],
      marketlist3:[],
      marketlist4:[],
      marketlist5:[],
      templateList:[],
      templateList1:[],
      templateList2:[],
      templateList3:[],
    }
  },
  created () {
	var type = this.$route.query.type;
	if (type){
		this.activeName = type + '';
	}
	  
	if (this.activeName == 1){
		 this.getlist()
	}else{
		this.gettemplatelist()
	}
  },
  computed: {
			...mapGetters(['pagemsg', 'pagename'])
		},
  methods: {
    gettemplatelist(){
      this.templateList = []
      this.templateList1 = []
      this.templateList2 = []
      this.templateList3 = []
      templateList.map(item=>{
				if ([5,2,11,3].indexOf(item.templateId) > -1){
					this.templateList.push(item);
				}
				if ([1,2,3,4,5,6,7,8,9,10,11].indexOf(item.templateId) > -1){
					this.templateList1.push(item);
				}
				if ([12,13,14].indexOf(item.templateId) > -1){
					this.templateList2.push(item);
				}
				if ([15,16,17].indexOf(item.templateId) > -1){
					this.templateList3.push(item);
				}
			})
    },
    getlist(){
      this.newmarketcompon = JSON.parse(JSON.stringify(marketcompon))
      this.marketlist1 = []
      this.marketlist2 = []
      this.marketlist3 = []
      this.marketlist4 = []
      this.marketlist5 = []
      this.newmarketcompon.map((v)=>{
        if([1,2,3].includes(v.templateId)){
          this.marketlist1.push(v)
        }
        if([4,5,6,7].includes(v.templateId)){
          this.marketlist2.push(v)
        }
        if([8,9,10,11,12,13].includes(v.templateId)){
          this.marketlist3.push(v)
        }
        if([14,15,16,17,18].includes(v.templateId)){
          this.marketlist4.push(v)
        }
        if([19,20,21,22,23].includes(v.templateId)){
          this.marketlist5.push(v)
        }
      })
    },
    handleClick(e){
      console.log(e.name)
      if(e.name == 1){
        this.getlist()
      }else{
        this.gettemplatelist()
      }
    },
    generateTitle,
     //获取打标签权限
    jumpautotag(e){
      // this.$router.push({
      //     name:"autoAddTagEdit",
      //     query:{
      //       templateId: e.templateId
      //     }
      //   })
      //创建打标签任务新增判断
      if(this.$store.state.permission.routerList.indexOf("autoAddTag")==-1){
           this.$message({
             type: 'error',
             message:"当前登录账号暂无创建自动打标签规则权限"
           })
      }else{
        this.$router.push({
          name:"autoAddTagEdit",
          query:{
            templateId: e.templateId
          }
        })
      }
    },
    //获取营销权限
    jumpautomarket(e){
    // 创建营销任务新增判断
    if(this.$store.state.permission.routerList.indexOf("automarket")==-1){
       this.$message({
             type: 'error',
             message:"当前登录账号暂无创建自动营销任务权限  "
           }) 
    }else{  
      this.$router.push({
                name: 'configuration',
                query: {
                   templateId: e.templateId
                   }
             })
    }
    
    // if(this.routerList.indexOf("automarket") !=-1){
    //       this.$message({
    //          title:"error",
    //          message:"当前登录账号暂无自动营销任务权限  "
    //        }) 
    // }else{
    //       this.$router.push({
    //             name: 'configuration',
    //             query: {
    //                templateId: e.templateId
    //                }
    //            })
    //     }
      // console.log(marketcompon.find((v)=>{return v.templateId == e.templateId }),'find')
      // console.log(e,'xx')
    },
  }
}
</script>

<style lang = "less" scoped>
  .titfont{
    font-size: 18px;
    color: #303133;
    white-space: nowrap;
    font-weight: bold;
    margin: 15px 0px 5px 10px;
    .secfont{
      font-size: 12px;
      color: #999999;
      font-weight: 100;
      margin:0px 0px 0px 20px;
    }
  }
  .flexRow{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
</style>