<template>
  <div style="margin:10px" :style="styles">
    <el-card :style="{width:width,height:height}" shadow = "never" class = "box" body-style = "padding:0px;width:100%">
      <div class = "fullbox" v-if="ispush" style="cursor: pointer;" @click="clickcreated">
        <i class = "iconplus el-icon-plus"></i>
        <div class = "iconplus-font">创建营销任务</div>
      </div>
      <div class = "fullbox" v-else>
        <div class = "allthebox">
          <div class = "topblue"></div>
          <div class = "bottombox">
            <div class = "flexRow" style="justify-content: space-between">
              <div class = "title fonthidden" style="width:150px">
                {{newmessage.title}}
              </div>
              <div style="font-size:12px;color:#409eff">[{{newmessage.tag}}]</div>
            </div>
            <div style="margin:10px 0px 5px 0px" class = "middle-grayfont">
              {{newmessage.description}}
            </div>
            <div class = "flexRow" style="align-items:flex-end;justify-content: space-between">
              <div>
                <div v-if="newmessage.showCount">
                  <div v-if="state==1">
                    <el-link type="primary" @click="getpeople">查看人数</el-link>
                  </div>
                  <div v-else-if="state==2">
                    <span style="font-size:14px;color:#303133;">
                      统计中...
                    </span>
                  </div>
                  <div v-else-if="state==3">
                    <span style="font-size:14px;color:#303133;">
                      {{num}}人
                    </span>
                  </div>
                </div>
              </div>
              <el-button type="primary" @click="btncreat" plain style="border:none">创建营销任务</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    
  </div>
</template>

<script>
import {autoMarketingTaskConfigtemplateMemberCount, getTemplateParam} from '@/api/sv1.0.0.js';
export default {
  props:{
    width:{
      type:String,
      default:'280px'
    },
    height:{
      type:String,
      default:'180px'
    },
    ispush:{
      type:Boolean,
      default:false
    },
    message:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    styles:{
      type: String,
      default: ''
    }
  },
  watch:{
    message(){
      this.newmessage = JSON.parse(JSON.stringify(this.message))
    },
  },
  created () {
      this.newmessage = JSON.parse(JSON.stringify(this.message))
  },
  data () {
    return {
      newmessage:{},
      state:1,
      num:0,
    }
  },
  methods: {
    clickcreated(){
      this.$emit('click')
    },
    btncreat(){
      this.$emit('click')
    },
    getpeople(){
      this.state = 2
      this.getgetTemplateParam().then((res)=>{
        // console.log(res,'123')
        this.gettemplateMemberCount(res)
      })
    },
    async gettemplateMemberCount(res){
      try{
        this.state = 2
        if(this.newmessage.templateId == 7){
          var num = res.PayOrderMemberCount ? +(Math.floor(res.PayOrderCount / res.PayOrderMemberCount)) : 0;
          var num1 = res.PayOrderMemberCount ? +(res.PayOrderMoney / res.PayOrderMemberCount).toFixed(2) : 0;
          num = num===0?'0':num;
          num1 = num1===0?'0':num1;
          this.newmessage.data.AutoMarketingTaskConfigDetailList[2].Num = num;
          this.newmessage.data.AutoMarketingTaskConfigDetailList[1].Num = num1;


        }else if(this.newmessage.templateId == 9){
          var num = res.GrowthValue === 0 ? '0':res.GrowthValue;
					this.newmessage.data.AutoMarketingTaskConfigDetailList[1].Num = res.GrowthValue;


        }else if(this.newmessage.templateId == 17){
          var num = res.CommissionMemberCount ? +(res.CommissionAwardMoney / res.CommissionMemberCount).toFixed(2) : 0;
          num = num===0?'0':num;
          this.newmessage.data.AutoMarketingTaskConfigDetailList[1].Num = num;
        }
        let data = this.modify()

        let result = await autoMarketingTaskConfigtemplateMemberCount({AutoMarketingTaskConfig:data})
        // console.log(result)
        if(result.IsSuccess){
          this.num = result.Result.MemberCount
          this.state = 3
        }
      }catch{
        this.state = 1
      }
    },
    modify(){
      let data = this.newmessage.data
      data.Id = 0
      data.UpdateTime = ''
      data.StartTime = ''
      data.EndTime = ''
      data.AddTime = ''
      data.State = ''
      data.MallId = ''
      data.Delstatus = ''
      if(data.AutoMarketingTaskConfigDetailList&&data.AutoMarketingTaskConfigDetailList.length){
        data.AutoMarketingTaskConfigDetailList = data.AutoMarketingTaskConfigDetailList.map((v)=>{
          v.Id = 0
          v.UpdateTime = ''
          v.AddTime = ''
          v.MallId = ''
          v.Delstatus = ''
          if(v.AutoMarketingTaskConfigDetailRelationIdList&&v.AutoMarketingTaskConfigDetailRelationIdList.length){
            v.AutoMarketingTaskConfigDetailRelationIdList = v.AutoMarketingTaskConfigDetailRelationIdList.map((x)=>{
              x.Id = 0
              x.UpdateTime = ''
              x.AddTime = ''
              x.MallId = ''
              x.Delstatus = ''
              return x
            })
          }
          return v
        })
      }
      if(data.AutoMarketingTaskConfigGiftList&&data.AutoMarketingTaskConfigGiftList.length){
        data.AutoMarketingTaskConfigGiftList = data.AutoMarketingTaskConfigGiftList.map((v)=>{
          v.Id = 0
          v.UpdateTime = ''
          v.AddTime = ''
          v.MallId = ''
          v.Delstatus = ''
          return v
        })
      }
      if(data.AutoMarketingTaskConfigMessageList&&data.AutoMarketingTaskConfigMessageList.length){
        data.AutoMarketingTaskConfigMessageList = data.AutoMarketingTaskConfigMessageList.map((v)=>{
          v.Id = 0
          v.UpdateTime = ''
          v.AddTime = ''
          v.MallId = ''
          v.Delstatus = ''
          return v
        })
      }
      if(data.AutoMarketingTaskConfigPushRuleLoop){
        data.AutoMarketingTaskConfigPushRuleLoop.Id = 0
        data.AutoMarketingTaskConfigPushRuleLoop.UpdateTime = ''
        data.AutoMarketingTaskConfigPushRuleLoop.AddTime = ''
        data.AutoMarketingTaskConfigPushRuleLoop.MallId = ''
        data.AutoMarketingTaskConfigPushRuleLoop.Delstatus = ''
      }
      return data
    },
    getgetTemplateParam(){
      return new Promise(async (res,rej)=>{
        try{
          let type = 0
          if(this.newmessage.templateId == 7){
            type = 1
          }else if(this.newmessage.templateId == 9){
            type = 2
          }else if(this.newmessage.templateId == 17){
            type = 3
          }
          // console.log(type)
          let result = await getTemplateParam({
            TemplateType:type
          })
          res(result)
        }catch{
          rej()
        }
        
      })  
    },
  }
}
</script>

<style lang = "less" scoped>
.box{
  display: flex;
}
.fullbox{
  width:100%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconplus{
  font-size: 18px;
  color: #409eff;
}
.iconplus-font{
  font-size: 16px;
  color: #409eff;
  margin-left:5px
}
.allthebox{
  width:100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .topblue{
    background: #409eff;
    height: 4px;
  }
  .bottombox{
    width:100%;
    flex:1;
    /* border:1px solid black; */
    padding: 25px 15px 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title{
      font-weight: bold;
      font-size: 16px;
      color:  #303133;
    }
    .middle-grayfont{
      font-size: 12px;
      color: #999999;
      line-height: 1.5;
    }
  }
}
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.fonthidden{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.fontvertical2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
/* .el-card ::v-deep .is-always-shadow{
  -webkit-box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%) !important;
} */
</style>
<style>

</style>